import { __assign } from "tslib";
import { reactive, defineComponent, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import LanguageUtil from "@/commons/LanguageUtil";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import UserInteractor from "@/domain/usecases/UserInteractor";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from 'vuex';
import routePath from "@/commons/RoutePath";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import AgreementsSubmit from "@/presentation/components/agreements-submit/AgreementsSubmit.vue";
import MyConfig from '@/_config';
import SelectOrganization from "@/presentation/components/select-organization/SelectOrganization.vue";
export default defineComponent({
    name: "UserRegisterProfile",
    components: {
        ErrorMessage: ErrorMessage,
        Breadcrumb: Breadcrumb,
        Input: Input,
        Textarea: Textarea,
        AgreementsSubmit: AgreementsSubmit,
        SelectOrganization: SelectOrganization,
    },
    setup: function () {
        var store = useStore();
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var _a = useForm({
            initialValues: {
                id: null,
                family_name: "",
                given_name: "",
                family_name_kana: "",
                given_name_kana: "",
                residence_prefecture_id: "",
                residence_city_id: "",
                residence_prefecture_name: "",
                residence_city_name: "",
                society_type: "SOCIETY",
                society_publish_type: "PUBLIC",
                language: "ja",
                nickname: "",
                is_mail_receivabled: 0,
                organization_name: "",
                corporate_number: null,
                organization_name_2: "",
                corporate_number_2: null,
                organization_name_3: "",
                corporate_number_3: null,
                reason_no_work_place: "",
                profile_publish_type: "PRIVATE",
                is_public_prefecture: 0,
                is_public_society_type: 0,
                is_public_graduation: 0,
                is_public_degree: 0,
                is_public_language: 1,
                alma_mater: "",
                academy_department: "",
                graduation_year: 0,
                industries: [],
                industry_optional: "",
                occupations: [],
                available_datetimes: []
            }
        }), isSubmitting = _a.isSubmitting, values = _a.values, errors = _a.errors, setValues = _a.setValues, setFieldValue = _a.setFieldValue, handleSubmit = _a.handleSubmit;
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            dataSignate: {},
            listBreadcrumb: [
                {
                    route: routePath.TOP,
                    name: "TOP"
                },
                {
                    route: "",
                    name: "基本情報入力"
                }
            ],
            listPrefectures: [],
            listCities: [],
            isLoading: false,
            errorValidateMessage: {},
            dataUser: null,
            message: "",
            processing: false,
            loadingDataDropdown: false,
            corporateNumberNoWorkPlace: MyConfig.corporateNumberNoWorkPlace,
            focusingOrganization: null,
            userRegisterData: store.state.userRegisterData,
        });
        //validate
        var id = useField("id", yup
            .number()).value;
        var family_name = useField("family_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))).value;
        var alma_mater = useField("alma_mater", yup
            .string()
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var academy_department = useField("academy_department", yup
            .string()
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var given_name = useField("given_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))).value;
        var family_name_kana = useField("family_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var given_name_kana = useField("given_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var is_public_prefecture = useField("is_public_prefecture", {}).value;
        var is_public_society_type = useField("is_public_society_type", {}).value;
        var is_public_graduation = useField("is_public_graduation", {}).value;
        var is_public_degree = useField("is_public_degree", {}).value;
        var is_public_language = useField("is_public_language", {}).value;
        var organization_name = useField("organization_name", function (value) {
            if ((!value || !value.toString().trim()) && state.dataSignate.society_type === '社会人') {
                return state.aLang.validation_required;
            }
            return true;
        }).value;
        var corporate_number = useField("corporate_number", yup
            .string().nullable()).value;
        var organization_name_2 = useField("organization_name_2", yup.string().nullable()).value;
        var corporate_number_2 = useField("corporate_number_2", yup.string().nullable()).value;
        var organization_name_3 = useField("organization_name_3", yup.string().nullable()).value;
        var corporate_number_3 = useField("corporate_number_3", yup.string().nullable()).value;
        var reason_no_work_place = useField("reason_no_work_place", yup.string()
            .nullable()
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var society_publish_type = useField("society_publish_type", yup
            .string()).value;
        var residence_prefecture_id = useField("residence_prefecture_id", yup
            .string()).value;
        var residence_city_id = useField("residence_city_id", yup
            .string()).value;
        var residence_prefecture_name = useField("residence_prefecture_name", yup
            .string()).value;
        var residence_city_name = useField("residence_city_name", yup
            .string()).value;
        var is_mail_receivabled = useField("is_mail_receivabled", yup.number()).value;
        var profile_publish_type = useField("profile_publish_type", yup
            .string()).value;
        var graduation_year = useField("graduation_year").value;
        var onSubmit = handleSubmit(function (values) {
            if (state.processing) {
                return;
            }
            values.is_public_prefecture = values.is_public_prefecture ? 1 : 0;
            values.is_public_society_type = values.is_public_society_type ? 1 : 0;
            values.is_public_graduation = values.is_public_graduation ? 1 : 0;
            values.is_public_degree = values.is_public_degree ? 1 : 0;
            values.is_public_language = values.is_public_language ? 1 : 0;
            // values.society_type = state.dataUser.society_type
            values.society_type = "";
            state.errorValidateMessage = {};
            state.message = "";
            var userProfile = __assign({}, values);
            // 企業ナンバーが13文字以外だとエラーになるため、キー自体を削除する
            for (var _i = 0, _a = Object.keys(userProfile); _i < _a.length; _i++) {
                var userKey = _a[_i];
                if (userProfile[userKey] === null) {
                    delete userProfile[userKey];
                }
            }
            return userInteractor.registerValidate(userProfile).then(function (result) {
                Object.assign(userProfile, state.dataSignate);
                store.commit("setUserRegisterData", userProfile);
                router.push(routePath.USER_REGISTER_CONFIRM);
            }).catch(function (error) {
                if (error && error.validation_errors) {
                    Object.assign(state.errorValidateMessage, error.validation_errors);
                }
                if (error && error.message) {
                    state.message = error.message;
                }
            });
        });
        var nowYear = new Date().getFullYear();
        function onUnselectedOrganization(organizationIndex) {
            switch (organizationIndex) {
                case 1: {
                    setValues({
                        corporate_number: null,
                        organization_name: null,
                    });
                    break;
                }
                case 2: {
                    setValues({
                        corporate_number_2: null,
                        organization_name_2: null,
                    });
                    break;
                }
                case 3: {
                    setValues({
                        corporate_number_3: null,
                        organization_name_3: null,
                    });
                    break;
                }
            }
        }
        function onSelectOrganization(organization, organizationIndex) {
            switch (organizationIndex) {
                case 1: {
                    setValues({
                        corporate_number: organization.corporateNumber,
                        organization_name: organization.name,
                    });
                    break;
                }
                case 2: {
                    setValues({
                        corporate_number_2: organization.corporateNumber,
                        organization_name_2: organization.name,
                    });
                    break;
                }
                case 3: {
                    setValues({
                        corporate_number_3: organization.corporateNumber,
                        organization_name_3: organization.name,
                    });
                    break;
                }
            }
        }
        function onCheckMailReceivabled(event) {
            setFieldValue("is_mail_receivabled", event.target.value ? 1 : 0);
        }
        function fillData() {
            if (state.dataUser && state.dataUser.name) {
                setFieldValue("nickname", state.dataUser.name);
            }
            else {
                state.isLoading = true;
                userInteractor.getUserAccountInfo().then(function (result) {
                    if (result.data) {
                        state.dataUser = result.data;
                        if (result.data.name) {
                            setFieldValue("nickname", result.data.name);
                        }
                    }
                    state.isLoading = false;
                }).catch(function (error) {
                    state.isLoading = false;
                });
            }
        }
        function initData() {
            state.processing = true;
            if (store.state.userRegisterData) {
                store.state.userRegisterData.is_public_prefecture = store.state.userRegisterData.is_public_prefecture ? true : false;
                store.state.userRegisterData.is_public_society_type = store.state.userRegisterData.is_public_society_type ? true : false;
                store.state.userRegisterData.is_public_graduation = store.state.userRegisterData.is_public_graduation ? true : false;
                store.state.userRegisterData.is_public_degree = store.state.userRegisterData.is_public_degree ? true : false;
                store.state.userRegisterData.is_public_language = store.state.userRegisterData.is_public_language ? true : false;
                setValues(store.state.userRegisterData);
                getCitiesByPerfecture(store.state.userRegisterData.residence_prefecture_id);
            }
            userInteractor.getUserAccountInfo().then(function (result) {
                if (result.data) {
                    state.dataUser = result.data;
                    state.dataSignate = result.data;
                    if (result.data.user_id) {
                        setFieldValue("id", result.data.user_id);
                    }
                    store.state.userRegisterData.society_type = state.dataUser.society_type;
                    setValues(store.state.userRegisterData);
                }
                state.processing = false;
            }).catch(function (error) {
                state.processing = false;
            });
        }
        function checkError(error, field) {
            if (!error[field]) {
                return false;
            }
            if (field == "organization_name") {
                if (state.errorValidateMessage["organization_name"]) {
                    state.errorValidateMessage["organization_name"] = [];
                }
                if (state.errorValidateMessage["organization_name_2"]) {
                    state.errorValidateMessage["organization_name_2"] = [];
                }
                if (state.errorValidateMessage["organization_name_3"]) {
                    state.errorValidateMessage["organization_name_3"] = [];
                }
                if (state.errorValidateMessage["corporate_number"]) {
                    state.errorValidateMessage["corporate_number"] = [];
                }
                if (state.errorValidateMessage["corporate_number_2"]) {
                    state.errorValidateMessage["corporate_number_2"] = [];
                }
                if (state.errorValidateMessage["corporate_number_3"]) {
                    state.errorValidateMessage["corporate_number_3"] = [];
                }
            }
            else {
                if (state.errorValidateMessage[field]) {
                    state.errorValidateMessage[field] = [];
                }
            }
            return true;
        }
        function getAllPrefecture() {
            return masterInteractor.getAllPerfecture().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listPrefectures = result.data;
                if (values.residence_prefecture_id) {
                    var prefectureName = getPerfectureName(values.residence_prefecture_id);
                    setValues({
                        residence_prefecture_id: values.residence_prefecture_id,
                        residence_prefecture_name: prefectureName
                    });
                }
            });
        }
        function getCitiesByPerfecture(prefecture_id) {
            if (!prefecture_id) {
                return;
            }
            return masterInteractor
                .getAllCitiesByPerfecture(prefecture_id)
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listCities = result.data;
                if (store.state.userRegisterData
                    .residence_city_id) {
                    setValues({
                        residence_city_id: store.state.userRegisterData
                            .project_place_city_id
                    });
                }
                if (values.residence_city_id) {
                    if (checkExistCities(values.residence_city_id)) {
                        var cityName = getCityName(values.residence_city_id);
                        setValues({
                            residence_city_name: cityName
                        });
                    }
                    else {
                        setValues({
                            residence_city_id: ""
                        });
                    }
                }
                else {
                    setValues({
                        residence_city_id: ""
                    });
                }
            });
        }
        function checkExistCities(project_place_city_id) {
            var cities = state.listCities.findIndex(function (o) { return o.id == project_place_city_id; });
            return cities != -1 ? true : false;
        }
        function onChangePrefecture(prefectureId) {
            state.listCities = [];
            if (!prefectureId) {
                setValues({
                    residence_prefecture_id: '',
                    residence_prefecture_name: ''
                });
                return;
            }
            var prefectureName = getPerfectureName(prefectureId);
            setValues({
                residence_city_id: "",
                residence_prefecture_name: prefectureName
            });
            getCitiesByPerfecture(prefectureId);
        }
        function onChangeCity(cityId) {
            if (!cityId) {
                setValues({
                    residence_city_id: '',
                    residence_city_name: ''
                });
                return;
            }
            var cityName = getCityName(cityId);
            setValues({
                residence_city_id: cityId,
                residence_city_name: cityName
            });
        }
        function getPerfectureName(id) {
            var perfecture = state.listPrefectures.filter(function (o) { return o.id == id; })[0];
            return perfecture ? perfecture.name : "";
        }
        function getCityName(id) {
            var city = state.listCities.filter(function (o) { return o.id == id; })[0];
            return city ? city.name : "";
        }
        function addEventInvalid() {
            var elements = document.querySelectorAll('input');
            var invalidListener = function (event) {
                event.target.scrollIntoView(false);
            };
            for (var i = elements.length; i--;)
                elements[i].addEventListener('invalid', invalidListener);
        }
        var enableNoWorkPlaceReason = computed(function () {
            return values.corporate_number === state.corporateNumberNoWorkPlace
                || values.corporate_number_2 === state.corporateNumberNoWorkPlace
                || values.corporate_number_3 === state.corporateNumberNoWorkPlace;
        });
        watch(function () { return enableNoWorkPlaceReason.value; }, function (enable) {
            if (!enable) {
                values.reason_no_work_place = "";
            }
        });
        onMounted(function () {
            initData();
            getAllPrefecture();
            addEventInvalid();
        });
        return {
            state: state,
            values: values,
            errors: errors,
            isSubmitting: isSubmitting,
            onSubmit: onSubmit,
            onUnselectedOrganization: onUnselectedOrganization,
            onSelectOrganization: onSelectOrganization,
            fillData: fillData,
            onCheckMailReceivabled: onCheckMailReceivabled,
            checkError: checkError,
            onChangePrefecture: onChangePrefecture,
            onChangeCity: onChangeCity,
            enableNoWorkPlaceReason: enableNoWorkPlaceReason,
            nowYear: nowYear,
        };
    }
});
