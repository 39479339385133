<template>
  <div class="user_register_profile">
    <div class="user_register_profile__body">
      <div class="user_register_profile__body__top mw-1000">
        <div class="user_register_profile__body__top__step">
          <span>会員登録</span>
          <span class="active">基本情報登録</span>
          <span>基本情報確認</span>
          <span>利用登録完了</span>
        </div>
        <p class="user_register_profile__body__top-title">マナビDX Quest 基本情報登録</p>
      </div>
      <div class="mw-1000">
        <div class="user_register_profile__body-message">
          <div class="user_register_profile__body-message__content">
            <div
              class="user_register_profile__body-message__content-text"
            >マナビDX Questを利用する上での情報を登録します。SIGNATE/SIGNATE Cloudで登録された情報を反映していますが、他のユーザーや企業に非公開にしたい場合は「公開する」のチェックを外してください。氏名は公開されません。</div>
          </div>
        </div>
        <div class="user_register_profile__body__form">
          <form class="horizontal" @submit="onSubmit">
            <div class="form-ctrl">
              <label for class="text none-flex">
                氏名
                <span class="required-text">（必須）</span>
                <br />
                <span class="text-active-2">※公開されません</span>
              </label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <Input
                      maxlength="64"
                      id="family_name"
                      type="text"
                      name="family_name"
                      placeholder="苗字をご入力ください"
                      autocomplete="off"
                      required="true"
                      v-model="values.family_name"
                    />
                    <ErrorMessage
                      v-if="checkError(errors,'family_name')"
                      :message="errors.family_name"
                    />
                    <template
                      v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.family_name
                      "
                    >
                      <ErrorMessage
                        v-for="message in state.errorValidateMessage
                          .family_name"
                        :message="message"
                        :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <Input
                      maxlength="64"
                      type="text"
                      id="given_name"
                      name="given_name"
                      placeholder="名前をご入力ください"
                      autocomplete="off"
                      required="true"
                      v-model="values.given_name"
                    />
                    <ErrorMessage
                      v-if="checkError(errors,'given_name')"
                      :message="errors.given_name"
                    />
                    <template
                      v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.given_name
                      "
                    >
                      <ErrorMessage
                        v-for="message in state.errorValidateMessage.given_name"
                        :message="message"
                        :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label for class="text none-flex">
                （氏名）カナ
                <span class="required-text">（必須）</span>
                <br />
                <span class="text-active-2">※公開されません</span>
              </label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <Input
                      maxlength="64"
                      id="family_name_kana"
                      type="text"
                      name="family_name_kana"
                      placeholder="苗字カナをご入力ください"
                      autocomplete="off"
                      required="true"
                      v-model="values.family_name_kana"
                    />
                    <ErrorMessage
                      v-if="checkError(errors,'family_name_kana')"
                      :message="errors.family_name_kana"
                    />
                    <template
                      v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.family_name_kana
                      "
                    >
                      <ErrorMessage
                        v-for="message in state.errorValidateMessage
                          .family_name_kana"
                        :message="message"
                        :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <Input
                      maxlength="64"
                      id="given_name_kana"
                      type="text"
                      name="given_name_kana"
                      placeholder="名前カナをご入力ください"
                      autocomplete="off"
                      required="true"
                      v-model="values.given_name_kana"
                    />
                    <ErrorMessage
                      v-if="checkError(errors,'given_name_kana')"
                      :message="errors.given_name_kana"
                    />
                    <template
                      v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.given_name_kana
                      "
                    >
                      <ErrorMessage
                        v-for="message in state.errorValidateMessage
                          .given_name_kana"
                        :message="message"
                        :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-ctrl">
              <label for class="text">ニックネーム</label>
              <div class="row">
                <div class="col text-right">
                  <div class="form-group" v-if="state.dataSignate">{{state.dataSignate.nickname  || "-" }}</div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label for="address" class="text">居住地</label>
              <div class="row">
                <div class="col-300">
                  <div class="form-group-row">
                    <div class="form-group">
                      <select
                        v-model="values.residence_prefecture_id"
                        @change="onChangePrefecture(values.residence_prefecture_id)"
                        :class="{ invalid: !values.residence_prefecture_id }"
                        class="select"
                      >
                        <option value="" selected>都道府県</option>
                        <template v-if="state.listPrefectures.length">
                          <option
                            v-for="perfecture in state.listPrefectures"
                            :key="perfecture"
                            :value="perfecture.id"
                          >
                            {{ perfecture.name }}
                          </option>
                        </template>
                      </select>
                      <ErrorMessage
                        v-if="errors.residence_prefecture_id"
                        :message="errors.residence_prefecture_id"
                      >
                      </ErrorMessage>
                    </div>
                    <div class="form-group">
                      <select
                        :class="{ invalid: !values.residence_city_id }"
                        class="select"
                        @change="onChangeCity(values.residence_city_id)"
                        v-model="values.residence_city_id"
                      >
                        <option value="" selected>市区町村</option>
                        <template v-if="state.listCities.length">
                          <option
                            v-for="cities in state.listCities"
                            :key="cities"
                            :value="cities.id"
                          >
                            {{ cities.name }}
                          </option>
                        </template>
                      </select>
                      <ErrorMessage
                        v-if="errors.residence_city_id"
                        :message="errors.residence_city_id"
                      ></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div class="line-height-50">
                  <span class="container-radio">
                    <input
                      name="is_public_prefecture"
                      type="checkbox"
                      id="is_public_prefecture"
                      v-model="values.is_public_prefecture"
                    />
                    <label for="is_public_prefecture">公開する</label>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label for class="text">
                学校名
                <br />
                <span class="text-active-2">※入力した場合は公開されます</span>
              </label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <Input
                      maxlength="255"
                      id="alma_mater"
                      type="text"
                      name="alma_mater"
                      placeholder="ご入力ください"
                      autocomplete="off"
                      v-model="values.alma_mater"
                    />
                    <ErrorMessage
                      v-if="checkError(errors,'alma_mater')"
                      :message="errors.alma_mater"
                    />
                    <template
                      v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.alma_mater
                      "
                    >
                      <ErrorMessage
                        v-for="message in state.errorValidateMessage
                          .alma_mater"
                        :message="message"
                        :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
                <div class="col-6">
                  <div class="line-height-50">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label for class="text">
                学部・研究科名
                <br />
                <span class="text-active-2">※入力した場合は公開されます</span>
              </label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <Input
                      maxlength="255"
                      type="text"
                      id="academy_department"
                      name="academy_department"
                      placeholder="ご入力ください"
                      autocomplete="off"
                      v-model="values.academy_department"
                    />
                    <ErrorMessage
                      v-if="checkError(errors,'academy_department')"
                      :message="errors.academy_department"
                    />
                    <template
                      v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.academy_department
                      "
                    >
                      <ErrorMessage
                        v-for="message in state.errorValidateMessage.given_name"
                        :message="message"
                        :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label for class="text">
                卒業年度
                <br />
                <span class="text-active-2">※入力した場合は公開されます</span>
              </label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <select v-model="values.graduation_year">
                      <option value=""></option>
                      <option value="1">在学中</option>
                      <option
                          v-for="year in 50"
                          :key="nowYear - year + 1"
                          :value="nowYear - year + 1"
                      >
                        {{ nowYear - year + 1 }}年度
                      </option>
                    </select>
                    <ErrorMessage
                        v-if="checkError(errors,'graduation_year')"
                        :message="errors.graduation_year"
                    />
                    <template
                        v-if="
                        state.errorValidateMessage &&
                          state.errorValidateMessage.graduation_year
                      "
                    >
                      <ErrorMessage
                          v-for="message in state.errorValidateMessage.graduation_year"
                          :message="message"
                          :key="message"
                      ></ErrorMessage>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-ctrl">
              <label for class="text none-flex">
                勤務先
                <span
                  class="required-text"
                  v-if="state.dataSignate.society_type === '社会人'"
                >
                  （必須）
                </span>
                <br />
                <span class="text-active-2">※公開されません</span><br>
                <span class="text-active-2">※現在の勤務先がある場合は必ず入力してください（協働企業側が、競合企業に所属する受講生とのマッチングはNGと判断する場合があるため）</span>
              </label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <div class="organization">
                      <SelectOrganization
                        id="organization_name"
                        name="organization_name"
                        v-model="values.organization_name"
                        :initOrganizationName="state.userRegisterData.organization_name"
                        :initCorporateNumber="state.userRegisterData.corporate_number"
                        :excludeCorporateNumbers="[values.corporate_number_2, values.corporate_number_3]"
                        @selected="onSelectOrganization($event, 1)"
                        @unselected="onUnselectedOrganization(1)"
                      />
                      <ErrorMessage
                          v-if="checkError(errors,'organization_name')"
                          :message="errors.organization_name"
                      />
                      <template v-if="state.errorValidateMessage && state.errorValidateMessage.organization_name">
                        <ErrorMessage
                            v-for="message_name in state.errorValidateMessage.organization_name"
                            :message="message_name"
                            :key="message_name"
                        ></ErrorMessage>
                      </template>
                      <template v-if="state.errorValidateMessage && state.errorValidateMessage.corporate_number">
                        <ErrorMessage
                            v-for="message_number in state.errorValidateMessage.corporate_number"
                            :message="message_number"
                            :key="message_number"
                        ></ErrorMessage>
                      </template>
                    </div>
                    <div class="organization">
                      <SelectOrganization
                        id="organization_name_2"
                        name="organization_name_2"
                        v-model="values.organization_name_2"
                        :initOrganizationName="state.userRegisterData.organization_name_2"
                        :initCorporateNumber="state.userRegisterData.corporate_number_2"
                        :excludeCorporateNumbers="[values.corporate_number, values.corporate_number_3]"
                        @selected="onSelectOrganization($event, 2)"
                        @unselected="onUnselectedOrganization(2)"
                      />
                      <ErrorMessage
                          v-if="checkError(errors,'organization_name_2')"
                          :message="errors.organization_name_2"
                      />
                      <template v-if="state.errorValidateMessage && state.errorValidateMessage.organization_name_2">
                        <ErrorMessage
                            v-for="message_name in state.errorValidateMessage.organization_name_2"
                            :message="message_name"
                            :key="message_name"
                        ></ErrorMessage>
                      </template>
                      <template v-if="state.errorValidateMessage && state.errorValidateMessage.corporate_number_2">
                        <ErrorMessage
                            v-for="message_number in state.errorValidateMessage.corporate_number_2"
                            :message="message_number"
                            :key="message_number"
                        ></ErrorMessage>
                      </template>
                    </div>
                    <div class="last_organization">
                      <SelectOrganization
                        id="organization_name_3"
                        name="organization_name_3"
                        v-model="values.organization_name_3"
                        :initOrganizationName="state.userRegisterData.organization_name_3"
                        :initCorporateNumber="state.userRegisterData.corporate_number_3"
                        :excludeCorporateNumbers="[values.corporate_number, values.corporate_number_2]"
                        @selected="onSelectOrganization($event, 3)"
                        @unselected="onUnselectedOrganization(3)"
                      />
                      <ErrorMessage
                          v-if="checkError(errors,'organization_name_3')"
                          :message="errors.organization_name_3"
                      />
                      <template v-if="state.errorValidateMessage && state.errorValidateMessage.organization_name_3">
                        <ErrorMessage
                            v-for="message_name in state.errorValidateMessage.organization_name_3"
                            :message="message_name"
                            :key="message_name"
                        ></ErrorMessage>
                      </template>
                      <template v-if="state.errorValidateMessage && state.errorValidateMessage.corporate_number_3">
                        <ErrorMessage
                            v-for="message_number in state.errorValidateMessage.corporate_number_3"
                            :message="message_number"
                            :key="message_number"
                        ></ErrorMessage>
                      </template>
                    </div>
                    <div class="attention" v-if="state.dataSignate.society_type === '社会人'">
                      ※1社は必須です
                      <br/>
                      ※現在お勤めの企業のみご記入ください。
                      <br/>
                      ※現在の勤務先、または業務委託中の企業が複数ある場合は最大3社までご入力可能です。
                      <br/>
                      ※3社を超える勤務先・業務委託先がある場合には事務局までご連絡ください。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AgreementsSubmit
              :isSubmitting="isSubmitting"
              buttonText="上記に同意し利用登録確認へ"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/register/profile/Profile.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/user/register/profile/Profile.scss";
</style>
